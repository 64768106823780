import {FlowEditorSDK} from '@wix/yoshi-flow-editor'
import {getPanelUrl} from '@wix/yoshi-flow-editor/utils'

export const openReservationsBmOverEditor = (editorSDK: FlowEditorSDK) =>
  editorSDK.editor.openDashboardPanel('', {
    url: '/restaurants/table-reservations',
    closeOtherPanels: false,
  })

export const openSettingsBmOverEditor = (editorSDK: FlowEditorSDK) =>
  editorSDK.editor.openDashboardPanel('', {
    url: '/restaurants/table-reservations/settings',
    closeOtherPanels: false,
  })

export const openBmLocationsSettings = (editorSDK: FlowEditorSDK) =>
  editorSDK.editor.openDashboardPanel('', {
    url: '/settings/business-info',
    closeOtherPanels: false,
  })

export const openAddonsModal = (editorSDK: FlowEditorSDK, t) =>
  editorSDK.editor.openModalPanel('', {
    url: getPanelUrl('ReservationAddOn', 'Addons'),
    width: 600,
    height: 592,
    title: t('editor-reservations.sidebar.addons'),
  })

export const EDITOR_OPEN_TABLE_RESERVATION_BM_EVENT_ID = 'EDITOR_OPEN_TABLE_RESERVATION_BM_EVENT_ID'
